import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withInMemoryScrolling } from '@angular/router';

import { provideLoaders } from '@frontend/loaders';
import { AppComponent, bootloader, provideVanillaCore } from '@frontend/vanilla/core';

import { appRoutes } from './app.routes';
import { provideCasino } from './provide';

if (process.env.NODE_ENV === 'production') {
    enableProdMode();
}

bootloader().then(() => {
    bootstrapApplication(AppComponent, {
        providers: [
            provideRouter(appRoutes, withInMemoryScrolling({ scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })),
            provideAnimationsAsync(),
            provideVanillaCore(),
            provideLoaders(),
            provideCasino(),
        ],
    }).catch();
});
