import { EnvironmentProviders, importProvidersFrom, makeEnvironmentProviders } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { HammerModule } from '@angular/platform-browser';

import { STORE_PREFIX, runOnAppInit } from '@frontend/vanilla/core';
import { GlobalSearchModule } from '@globalsearch/productstub';
import player from 'lottie-web';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';

import { CasinoBootstrapService } from './bootstrap/casino-bootstrap.service';
import { CasinoMenuActionsBootstrapService } from './casino-menu/casino-menu-actions-bootstrap.service';
import { HostAppBootstrapper } from './host-app/host-app-bootstrapper.service';
import { PokerLeaderboardFeedPageMatrixBootstrapService } from './poker-leaderboard-feed/poker-leaderboard-feed-bootstrap.service';

export function provideCasino(): EnvironmentProviders {
    return makeEnvironmentProviders([
        { provide: STORE_PREFIX, useValue: 'casino.' },
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: {} },
        runOnAppInit(HostAppBootstrapper),
        runOnAppInit(CasinoBootstrapService),
        runOnAppInit(CasinoMenuActionsBootstrapService),
        runOnAppInit(PokerLeaderboardFeedPageMatrixBootstrapService),
        importProvidersFrom([
            HammerModule,
            MatDialogModule,
            GlobalSearchModule.forRoot(),
            NgCircleProgressModule.forRoot({
                backgroundPadding: -3,
                radius: 24,
                space: -4,
                outerStrokeGradient: false,
                outerStrokeWidth: 4,
                outerStrokeColor: '#82c248',
                innerStrokeColor: '#585858',
                innerStrokeWidth: 4,
                title: '',
                animateTitle: false,
                animationDuration: 1000,
                showSubtitle: false,
                showUnits: false,
                showBackground: false,
                clockwise: false,
                startFromZero: false,
                animation: false,
                titleColor: '#000000',
                titleFontSize: '18',
                titleFontWeight: '900',
            }),
            LottieCacheModule.forRoot(),
            LottieModule.forRoot({ player: () => player }),
        ]),
    ]);
}
